import React, { useEffect, useState } from 'react';
import { GlobalWrapper } from '@global/styles/grid';
import { StaticQuery, graphql } from 'gatsby';
import { CMSParseImage } from '@components/StrapiComponents/ImageParser';
import { transformToSlug } from 'helpers/slugHelper';
import { PATHS } from '@global/constants/urls';
import {
  DataContainerSkeleton,
  LoadingSkeleton,
  LoadingImageSkeleton,
} from '@sections/ResourcesCenterHero/resourcesCenterHero.styles';
import {
  CaseStudieContainer,
  CaseStudiesTopicContainer,
  CaseStudieTitle,
  CaseStudieTitleContainer,
  CaseStudieTitleTooltip,
  CaseStudieTopic,
  CaseStudieWrapper,
  Container,
  DataContainer,
  HeroWrapper,
  ImageContainer,
  Tag,
  TitleContainer,
} from './caseStudiesIndexHero.styles';
import { CasesIndexHeroProps } from './caseStudiesIndexHero.types';

const CaseStudiesIndexHeroComponent = ({ data }: CasesIndexHeroProps) => {
  const [renderTooltip, setRenderTooltip] = useState<string[]>();
  const spotlightCase = data.cms.caseStudies.data
    .sort((case1, case2) => {
      return new Date(case1.attributes.Publication_Date) <
        new Date(case2.attributes.Publication_Date)
        ? 1
        : -1;
    })
    .slice(0, 4);

  const skeletonComponent = () => {
    return Array.from({ length: 4 }, () => (
      <CaseStudieWrapper>
        <DataContainerSkeleton className="data-container">
          <Tag>
            <LoadingSkeleton />
          </Tag>
          <CaseStudieTitle>
            <LoadingSkeleton count={2} isFirstLineSkeleton className="secondary-title" />
          </CaseStudieTitle>
          <LoadingSkeleton />
        </DataContainerSkeleton>
        <ImageContainer>
          <LoadingImageSkeleton />
        </ImageContainer>
      </CaseStudieWrapper>
    ));
  };

  useEffect(() => {
    const isEllipsisActive = (element) => {
      return element.offsetHeight + 2 < element.scrollHeight;
    };

    const elements = document.querySelectorAll('.material-title');
    const renderTooltipTitle: string[] = [];
    elements.forEach((el) => {
      if (isEllipsisActive(el)) {
        renderTooltipTitle.push(el.innerText);
      }
    });
    setRenderTooltip(renderTooltipTitle);
  }, []);

  return (
    <Container>
      <GlobalWrapper>
        <TitleContainer>Case Studies</TitleContainer>
        <HeroWrapper>
          {!data && spotlightCase.length === 0 ? (
            <CaseStudieContainer>
              {skeletonComponent().map((component) => component)}
            </CaseStudieContainer>
          ) : (
            <CaseStudieContainer>
              {spotlightCase.map((caseStudie) => (
                <CaseStudieWrapper
                  href={`${PATHS.CASESTUDIES}/${transformToSlug(
                    caseStudie.attributes.slug.toLowerCase(),
                  )}`}
                >
                  <DataContainer className="data-container">
                    <Tag>Case Study</Tag>
                    <CaseStudieTitleContainer>
                      <CaseStudieTitle className="case-studies-title">
                        {caseStudie.attributes.Title}
                      </CaseStudieTitle>
                      {renderTooltip?.includes(caseStudie.attributes.Title) && (
                        <CaseStudieTitleTooltip className="case-studies-title-tooltip">
                          {caseStudie.attributes.Title}
                        </CaseStudieTitleTooltip>
                      )}
                      <CaseStudiesTopicContainer>
                        <CaseStudieTopic
                          href={`${PATHS.RESOURCE_CENTER}/?filter=${caseStudie.attributes.Primary_Field}`}
                        >
                          {caseStudie.attributes.Primary_Field}
                        </CaseStudieTopic>
                        <CaseStudieTopic
                          href={`${PATHS.RESOURCE_CENTER}/?filter=${caseStudie.attributes.Secondary_Field}`}
                        >
                          {caseStudie.attributes.Secondary_Field}
                        </CaseStudieTopic>
                      </CaseStudiesTopicContainer>
                    </CaseStudieTitleContainer>
                  </DataContainer>
                  <ImageContainer>
                    {CMSParseImage(caseStudie.attributes.Small_Image)}
                  </ImageContainer>
                </CaseStudieWrapper>
              ))}
            </CaseStudieContainer>
          )}
        </HeroWrapper>
      </GlobalWrapper>
    </Container>
  );
};

const CaseStudiesIndexHero = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          cms {
            caseStudies(filters: { Spotlight_Material: { eq: true } }) {
              data {
                attributes {
                  slug
                  Title
                  Small_Image {
                    data {
                      attributes {
                        alternativeText
                        url
                        mime
                        urlJSON
                      }
                    }
                  }
                  Primary_Field
                  Secondary_Field
                }
              }
            }
          }
        }
      `}
      render={(data) => <CaseStudiesIndexHeroComponent data={data} />}
    />
  );
};

export default CaseStudiesIndexHero;
